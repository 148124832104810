.title {
  margin: 10px 0;
  font-size: $fs-25;
  text-align: center;
}
.keys {
  overflow: hidden;
  text-align: left;
  white-space: break-spaces;
}

.keyElement {
  overflow: hidden;
  height: 25px;
  white-space: pre-line;
  text-overflow: ellipsis;
}

.upload-successful {
  color: #17a085;
  font-size: 20px;
  margin-left: 10px;
}

.divLine {
  width: 100%;
  margin: 22.5px 5px 22.5px 5px;
  border-bottom: 1px solid gray;
  opacity: 0.2;
}

.ui-button {
  @extend .body;
  @extend .body--bold;

  display: flex;
  line-height: 1.43;
  outline: none;
  border: none;
  border-radius: 4px;
  color: $white;
  background-color: $dodger-blue;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: $light-grey-blue;
  }

  &:not(:disabled):hover {
    background-color: $dodger-blue-hover;
  }

  &--outline {
    border: 1px solid $very-light-blue;
    color: $charcoal-grey;
    background-color: transparent;

    &:disabled {
      border-color: $light-grey-blue;
    }

    &:not(:disabled):hover {
      border-color: $light-grey-blue;
      background-color: transparent;
    }
  }
}

.notAuthorizedContainer {
  display: flex;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.warning {
  font-size: $fs-40;
  color: $warm-pink;
}
.message {
  display: flex;
  font-size: $fs-30;
  flex-direction: row;
  .text {
    padding-top: 12px;
    line-height: 70px;
  }
}

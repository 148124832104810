.ui-pagination {
  display: flex;
  align-items: center;

  &-container {
    margin-bottom: 72px;
  }

  .ui-button {
    @extend .body--bold;

    width: 40px;
    height: 40px;
    border-radius: 0;
    background-color: $charcoal-grey;

    &:hover {
      background-color: $charcoal-grey-hover;
    }

    &.active {
      box-shadow: 0 2px 32px 0 rgba(42, 42, 42, 0.48);
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &--outline {
      border-color: $very-light-blue;
      background-color: $white;

      &:hover {
        color: $white;
        background-color: $charcoal-grey-hover;
      }
    }

    &.prev-next {
      width: 50px;
      &:hover {
        path {
          fill: $white;
        }
      }
    }
  }
}

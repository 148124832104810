.dashboard {
  @extend .body;

  &-container {
    height: 100vh;
    padding-top: 72px;
    &.notAuthorized {
      padding-top: 0;
    }
  }

  &-content-wrap {
    min-height: 100%;
    background-color: $pale-grey;
  }

  .ui-icon {
    &_wrapper {
      display: flex;
      align-items: center;
    }
  }

  .content {
    padding: 24px;

    &__with-sidebar {
      padding: 16px 24px 0 72px;

      &--static {
        padding-left: $container-padding-with-sidebar;
      }
    }

    &__with-topbar {
      padding-top: 16px + $top-bar-height;
    }
  }

  &-navbar {
    height: $nav-bar-height;
    padding: 0.5rem 1.5rem;
    background-color: $charcoal-grey;

    .logo {
      text-align: center;
    }

    .ui-avatar {
      margin-right: 8px;
      border: solid 2px $light-alice-blue;
      border-radius: 50%;
    }

    .link-title {
      padding: 4px 12px !important;
      border-radius: 4px;
      color: $white;

      &.active {
        @extend .body--bold;

        background-color: $gunmetal;
      }

      &:hover {
        @extend .body--bold;
      }
    }

    .ui-select {
      &__control {
        width: 240px;
        height: 40px;
        min-height: 0;
        margin-right: 2px;
        border: transparent;
        background-color: $gunmetal;
      }

      &__input,
      &__single-value,
      &__indicator {
        color: $white;
      }

      &__menu {
        border-color: transparent;
        background-color: $dark;
      }

      &__option {
        color: $white;
        background-color: transparent;

        &:hover {
          background-color: $gunmetal;
        }
      }
    }

    .dropdown {
      &-title {
        display: flex;
        height: 40px;
        padding: 12px 8px !important;
        border-radius: 4px;
        color: $white;
        background-color: $gunmetal;
        align-items: center;

        &__no-bg {
          background-color: transparent;
        }
      }

      &.group-select {
        margin-right: 2px;

        .dropdown-title {
          display: flex;
          width: 240px;
          padding: 10px 16px !important;
          align-items: center;
          justify-content: space-between;
        }
      }

      &.show {
        .chevron-icon {
          transform: rotate(180deg);
        }
      }

      &-list {
        width: 100%;
        min-width: 0;
        margin: 0;
        border: none;
        border-radius: 0 0 4px 4px;
        background-color: $dark;
        box-shadow: 0 -1px 0 0 #295065;

        &__right {
          width: auto;
        }
      }

      &-item {
        @extend .body;

        display: flex;
        height: 32px;
        padding: 0 16px !important;
        color: $white;
        align-items: center;

        &:hover {
          background-color: $gunmetal;
        }
      }
    }
  }

  &-sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1001;
    width: 48px;
    height: calc(100% - 72px);
    border: solid 1px $very-light-blue;
    background-color: $white;
    transition: width 0.4s ease-in;

    &.open,
    &.static-open {
      overflow-y: auto;
      width: $sidebar-open-width;
      transition: width 0.4s ease-out;
    }

    .arrow-line-icon {
      width: 14px;
      transform: rotate(180deg);
      cursor: pointer;

      &__rotated {
        transform: rotate(0deg);
      }
    }
  }

  &-bottombar {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    width: calc(100% - 48px);
    height: $bottom-bar-height;
    border: solid 1px $very-light-blue;
    background-color: $white;

    .ui-button {
      width: 240px;
      margin-right: 16px;
    }
  }
}

.ribbon {
  position: absolute;
  top: 55px;
  left: -5rem;
  z-index: 99999;
  overflow: hidden;
  width: 18rem;
  white-space: nowrap;
  opacity: 0.75;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  pointer-events: none;
  &.staging {
    background-color: #fa2e2e;
  }
  &.uat {
    background-color: #006400;
  }
  span {
    display: block;
    margin: 1px 0;
    padding: 10px 50px;
    font-size: $fs-22;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    color: var(--white);
    pointer-events: none;
  }
}

.ui-modal {
  @extend .body;

  width: auto;
  padding: $modal-padding;
  border: none;
  border-radius: 0;

  &-backdrop {
    background-color: $light-grey-blue;

    &.show {
      opacity: 0.72;
    }
  }

  &-dialog {
    display: flex;
    max-width: none;
    margin: 5rem auto;
    justify-content: center;
  }

  .header {
    display: flex;
    flex-direction: column;
  }
}

.ui-table {
  border: none;

  &.empty {
    min-height: 150px;
  }

  .rt-tbody {
    overflow: hidden;
  }

  .ui-thead {
    @extend .body;
    @extend .body--bold;

    box-shadow: none !important;

    & .ui-thead-th {
      display: flex;
      overflow: visible;
      padding: 0;
      line-height: 1.43;
      text-align: left;
      border: none;
      align-items: center;
      cursor: pointer;

      & > div {
        width: 100%;
      }
    }

    .header-cell {
      @extend .body--bold;

      padding: 10px 12px;
      white-space: normal;
      color: $gunmetal;
      word-wrap: break-word;

      .with-opacity {
        opacity: 0.72;
      }

      .active {
        color: $charcoal-grey;
      }
      &.noSorting {
        cursor: default;
      }
    }

    .select-all {
      padding: 10px 12px;
    }
  }

  .ui-trgroup {
    @extend .body;

    margin-bottom: 8px;
    border: solid 1px $very-light-blue !important;
    border-radius: 2px;
    background-color: $white;

    &:hover {
      border-color: $very-light-blue !important;
      box-shadow: 0 2px 4px 0 rgba(196, 203, 210, 0.3);
    }

    & .ui-td {
      display: flex;
      padding: 9.5px 12px;
      border: none !important;
      align-items: center;

      &__no-val {
        color: $light-grey-blue;
      }
    }
  }

  .dots-icon {
    align-items: center;
    cursor: pointer;
  }

  .ui-badge {
    margin: 0 8px 4px 0;
    padding: 2.5px;
    line-height: 10px;
    text-align: center;
    border: outset 0.5px $very-light-blue;
    border-radius: 2px;
    background: whitesmoke;
    &.dots {
      border: none;
      background: none;
      cursor: pointer;
    }
    &.ppc {
      background-color: deepskyblue;
    }
  }

  .license:empty {
    display: none;
  }

  .license:not(:first-child)::before {
    content: ', ';
  }
}

.keys {
  overflow: hidden;
  text-align: left;
  white-space: break-spaces;
}

.keyElement {
  overflow: hidden;
  height: 25px;
  white-space: pre-line;
  text-overflow: ellipsis;
}

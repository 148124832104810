.ui-checkbox {
  display: flex;
  margin-bottom: 0;
  line-height: 1.43;
  align-items: center;
  cursor: pointer;

  @extend .body;

  &__tick {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid $pantone;
    border-radius: 2px;

    &--mr {
      margin-right: 0.75rem;
    }
  }

  & [type='checkbox'] {
    position: absolute;
    z-index: 1;
    width: 0;
    opacity: 0;

    &:checked {
      + .ui-checkbox__tick {
        border: 1px solid $dodger-blue;
        background-color: $dodger-blue;

        &--intermid {
          &::after {
            position: absolute;
            top: 6px;
            left: 2px;
            width: 10px;
            height: 2px;
            content: '';
            background-color: $white;
          }
        }
      }
    }
  }
}

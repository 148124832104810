.ui-edit-modal {
  @extend .body;

  width: 688px;
  min-height: 520px;
  border-radius: 4px;
  box-shadow: 4px 4px 40px 0 rgba(50, 61, 69, 0.24);

  &.profile-modal {
    width: 922px;
  }

  &.deactivate-modal,
  &.statistics-modal,
  &.moveUser-modal,
  &.confirmation-modal,
  &.delete-modal {
    min-height: 200px;
  }
  &.upload-modal {
    min-height: 200px;

    .drop-zone {
      @extend .title--h2;

      display: flex;
      height: 160px;
      border: 1px dashed $very-light-blue;
      border-radius: 4px;
      color: $light-grey-blue;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.isDrag {
        border-color: $dodger-blue;
        color: $dodger-blue;
      }
    }
  }

  .ui-input {
    height: 48px;
  }

  .ui-button {
    max-width: 240px;
  }

  .divider-line {
    width: 100%;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid $very-light-blue;

    &.fullWidth {
      width: calc(#{$modal-padding} * 2 + 100%);
      border-top-color: $light-grey-blue;
      transform: translateX(-32px);
    }
  }

  .optional-text {
    @extend .body;

    color: $light-grey-blue;
  }

  .doc-type-tabs {
    display: flex;

    .ui-button {
      width: 100%;
      border-radius: 4px 0 0 4px;

      &.active {
        background-color: $charcoal-grey;
        box-shadow: 0 2px 32px 0 rgba(42, 42, 42, 0.48);
      }
    }

    .tab-toggle {
      width: 30px;
      margin-left: -1px;
      border-radius: 0 4px 4px 0;

      &.active {
        border-left: 1px solid $light-grey-blue;
      }
    }

    .doc-tab {
      display: flex;
      width: 100%;
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .add-all-btn {
    @extend .body--sm;
    @extend .body--bold;

    display: flex;
    padding-top: 2px;
    color: $light-grey-blue;
    align-items: center;
  }
}

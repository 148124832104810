.ui-sidebar {
  display: flex;
  height: 100%;
  padding-bottom: 1.5rem;
  flex-direction: column;
  justify-content: space-between;

  .item {
    height: 20px;
    padding-left: 51px;
    border-left: 4px solid transparent;
    cursor: pointer;

    &.active {
      @extend .body--bold;

      border-left-color: $dodger-blue;
    }
  }

  &__collapsed {
    display: flex;
    height: 100%;
    padding: 24px 7px;
    flex-direction: column;

    .item {
      display: flex;
      width: 32px;
      height: 32px;
      margin-bottom: 0.5rem;
      border: 1px solid $very-light-blue;
      border-radius: 4px;
      justify-content: center;

      &.active {
        border: 1px solid $dodger-blue;
      }
    }
  }
}

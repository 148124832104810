.ui-datepicker {
  .DayPicker {
    line-height: 1.5;

    @extend .body--extra-sm;

    &-Navbutton {
      position: absolute;
      border: 1px solid $very-light-blue;
      cursor: pointer;

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    &-Month {
      margin: 0;
    }

    &-Caption {
      margin-top: 3px;
      margin-bottom: 1rem;
      text-align: center;

      & > div {
        @extend .body;
        @extend .body--bold;
      }
    }

    &-Weekday {
      @extend .body;
      @extend .body--extra-sm;

      padding: 0 0.4rem;
    }

    &-Day {
      @extend .body;
      @extend .body--extra-sm;

      padding: 0.4rem 0.5rem;
      border-radius: 0;

      &--selected {
        &:not(.DayPicker-Day--outside) {
          color: $white;
          background-color: $dodger-blue;
        }

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
          background-color: $dodger-blue-hover;
        }

        &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          color: $white;
          background-color: $dodger-blue-hover;
        }
      }

      &--start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &--end {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      &--disabled {
        opacity: 0.6;
      }
    }
  }

  .DayPickerInput {
    &-Overlay {
      padding: 16px;
      border-radius: 4px;
    }
  }

  .ui-date-input {
    position: relative;

    .ui-input {
      width: 199px;
      padding-left: 16px;
    }

    .ui-icon_wrapper {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
}
.Selectable {
  font-size: $em-85;
}
.DayPicker-Navbutton-container {
  display: flex;
  justify-content: space-between;
}

:root {
  --text-black: #{$text-black};
  --dodger-blue: #{$dodger-blue};
  --dodger-blue-hover: #{$dodger-blue-hover};
  --charcoal-grey: #{$charcoal-grey};
  --charcoal-grey-hover: #{$charcoal-grey-hover};
  --gunmetal: #{$gunmetal};
  --blue-green: #{$blue-green};
  --very-light-blue: #{$very-light-blue};
  --pale-grey: #{$pale-grey};
  --white: #{$white};
  --dark: #{$dark};
  --light-grey-blue: #{$light-grey-blue};
  --light-alice-blue: #{$light-alice-blue};
  --warm-pink: #{$warm-pink};
  --light-pink: #{$light-pink};
  --butterscotch: #{$butterscotch};
  --pale: #{$pale};
  --pantone: #{$pantone};
}

html {
  font-family: $lato;
}

body {
  color: $charcoal-grey;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: $color-inherit;
}

svg {
  width: 100%;
  height: 100%;
  vertical-align: inherit;
}

button {
  padding: 0;
  border: none;
  color: $color-inherit;
  background-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.spinner {
  width: 3rem;
  height: 3rem;
  color: $dodger-blue;
}

.text-overflow-dots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
}

// Helper class to prevent shrinking flex items in Firefox https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
.mh-0 {
  min-height: 0;
}

// Helper class to prevent text wrapping when collapsing content
.text-no-wrap {
  overflow: hidden;
  white-space: nowrap;
}

// Mixin to have helper classes .m-t-1 => margin-top: 1rem; etc.
$spaceAmounts: (16, 32);
$sides: (top, bottom, left, right);

@each $space in $spaceAmounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.ui-icon {
  &_wrapper {
    display: flex;
  }
}

.ui-input {
  @extend .body;

  border: solid 1px $very-light-blue;
  border-radius: 4px;

  &::placeholder {
    color: $light-grey-blue;
  }

  &.textarea {
    min-height: 96px;
  }
}

.press-enter-text {
  color: $charcoal-grey;
}

.tooltip-content {
  @extend .body;
}

.ui-no-data {
  @extend .title;
  @extend .title--h1;

  height: 244px;
  padding-top: 56px;
  background-color: $white;

  .ui-button {
    width: 240px;
  }
}

.ui-filter-dropdown {
  z-index: 2;
  width: 232px;
  padding: 16px;
  border: solid 1px $very-light-blue;
  border-radius: 4px;
  color: $gunmetal;
  background-color: $white;

  &--date {
    width: 460px;
  }

  .dropdown-content {
    .ui-icon_wrappper {
      justify-content: flex-end;
    }
  }
}

.ui-row-option-container {
  width: 140px;
  border: solid 1px $very-light-blue;
  border-radius: 4px;
  color: $gunmetal;
  background-color: $white;
  box-shadow: 0 2px 31px -16px rgba(0, 0, 0, 0.5);

  .ui-dropdown {
    &-menu {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    &-item {
      display: flex;
      height: 32px;
      padding-right: 16px;
      padding-left: 16px;
      color: $gunmetal !important;
      align-items: center;
      cursor: pointer;

      a {
        text-decoration: none;
        color: $gunmetal !important;
      }

      &:hover {
        background-color: $light-grey-blue;
      }
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.active-indicator {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: $warm-pink;

  &.active {
    background-color: $blue-green;
  }
}

.flag-icon-2x {
  font-size: $flag-font-size !important;
}

.error {
  border: 1px solid red;
  border-radius: 4px;
}

.errorMessage {
  font-size: $fs-10;
  color: $warm-pink;
}

.adminMessage {
  padding-top: 5px;
}

.addAll {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: $light-grey-blue;
}

.generateStatistics {
  display: inline-grid;
}

.clearDate {
  position: absolute;
  margin-left: 10px;
}

.switch {
  width: 170px;
  height: 30px;
  vertical-align: middle;
  div {
    width: 50%;
    padding-right: 4px;
    padding-left: 4px;
    font-size: $fs-12;
    line-height: 30px;
    outline: none;
    text-align: center;
    border: 1px solid black;
    cursor: pointer;
    &.left {
      border-radius: 12px 0 0 12px;
      border-right: 0;
    }
    &.right {
      border-radius: 0 12px 12px 0;
      border-left: 0;
    }
    &.notAllowed {
      cursor: not-allowed;
    }
  }
  .active {
    color: $white;
    background-color: $dodger-blue;
    &.left {
      border-right: 1px solid black;
    }
    &.right {
      border-left: 1px solid black;
    }
  }
}

.selectAll {
  cursor: pointer;
}
.logo {
  display: inline;
  width: 92.5px;
  height: 37px;
  margin-right: 5px;
  vertical-align: baseline;
}
.logoContainer {
  padding: 1px;
  border-radius: 2px;
  background-color: #fff;
  opacity: 0.7;
}

.custom-flag {
  width: 24.27px;
}

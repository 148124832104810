.svg-inline--fa {
  font-size: 22.5px !important;
  margin: 5px 0px;
}
.fa-fw {
  margin: 7.5px 0px;
  width: 22.25em;
}

.bell-icon {
  margin-left: 1.5px !important;
}

.file-icon {
  margin-left: 1px !important;
}

.warning-icon {
  margin-left: -2px;
}

.na-container {
  display: contents;
  svg{
    height: 75%;
    margin-top: 5px;
  }
}

.resetColorContainer {
  display: contents;
  cursor: pointer;
  svg{
    width: 12px;
    margin-left: 16px
  }
}

.icons-row{
  height: 34.5px;
}

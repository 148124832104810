.title {
  margin: 0;
  font-family: $lato;
  font-style: normal;
  letter-spacing: normal;
  color: $text-black;
  font-stretch: normal;

  &--bold {
    font-weight: bold;
  }

  &--h1 {
    font-size: $fs-28;
    font-weight: normal;
    line-height: 1.21;
  }

  &--h2 {
    font-size: $fs-20;
    font-weight: normal;
    line-height: 1.43;
  }
}

.body {
  font-family: $lato;
  font-size: $fs-14;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  color: $charcoal-grey;
  font-stretch: normal;

  &--sm {
    font-size: $fs-12;
    line-height: 1.5;
  }

  &--extra-sm {
    font-size: $fs-10;
    line-height: 1.6;
  }

  &--bold {
    font-weight: bold;
  }
}

$lato: 'Lato', sans-serif !default;
$color-inherit: inherit;

$text-black: #2a2a2a;
$dodger-blue: #4680ff;
$dodger-blue-hover: #4671ff;
$charcoal-grey: #323d45;
$charcoal-grey-hover: #3a4750;
$gunmetal: #465057;
$blue-green: #17a085;
$very-light-blue: #e0e7f1;
$pale-grey: #f1f1f2;
$white: #fff;
$dark: #1d272e;
$light-grey-blue: #b3bcc3;
$light-alice-blue: #edf2f6;
$warm-pink: #ff4e6b;
$light-pink: #ffd8de;
$butterscotch: #ffba4e;
$pale: #ffecce;
$pantone: #565b5a;

$fs-40: 40px;
$fs-30: 30px;
$fs-28: 28px;
$fs-25: 25px;
$fs-22: 22px;
$fs-20: 20px;
$fs-14: 14px;
$fs-12: 12px;
$fs-10: 10px;
$em-85: 0.85em;

$bottom-bar-height: 64px;
$top-bar-height: 80px;
$nav-bar-height: 72px;
$container-padding-with-sidebar: 289px;
$sidebar-open-width: 256px;
$modal-padding: 32px;
$notification-z-index: 6000;

$flag-font-size: 1.3em;

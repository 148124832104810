.ui-notification {
  position: fixed;
  bottom: 32px;
  z-index: $notification-z-index;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  .ui-toast {
    padding: 14px 16px;
    border-radius: 8px;
    color: $white;
    background-color: $charcoal-grey;
  }
}

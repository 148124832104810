.ui-select-container {
  .ui-select {
    &__control {
      overflow: auto;
      min-height: 50px;
      max-height: 150px;
      border: solid 1px $very-light-blue;
      box-shadow: none;

      &:hover {
        border-color: $very-light-blue;
      }
    }

    &__input {
      letter-spacing: 0.21px;
    }

    &__placeholder {
      color: $light-grey-blue;
    }

    &__menu {
      margin: 0;
      border: solid 1px $light-grey-blue;
      border-top: none;
      box-shadow: none;
    }

    &__menu-list {
      max-height: 200px;
    }

    &__option {
      display: flex;
      height: 32px;
      align-items: center;

      &--is-selected {
        color: $white;
        background-color: $gunmetal;
      }
    }

    &__dropdown-indicator {
      width: 36px;
    }

    &__clear-indicator {
      float: left;
      width: 36px;
    }
  }
  &.multi {
    .ui-select {
      &__control {
        &--menu-is-open {
          border: solid 1px $very-light-blue;
          border-radius: 4px;
        }
      }

      &__multi-value {
        height: 32px;
        border: solid 1px $very-light-blue;
        border-radius: 2px;
        background-color: transparent;

        &__label {
          @extend .body;

          display: flex;
          padding-left: 8px;
          align-items: center;
        }

        &__remove {
          width: 22px;
          min-width: 22px;
        }
      }
    }
  }
}
.ui-select__indicators {
  display: block !important;
}

.accessBox {
  width: 105px;
}
.radioButton {
  margin: 5px 0;

  label {
    position: relative;
    display: inline-block;
    height: 20px;
    margin-bottom: 0;
    padding: 0 10px;
    vertical-align: bottom;
    cursor: pointer;
  }
}
